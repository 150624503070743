import {createStore} from 'vuex'

export default createStore({
    state: {
        translate: null,
        locale: 'en',
    },
    getters: {
        getTranslate(state) {
            return state.translate;
        },
        getLocale(state) {
            return state.locale;
        }
    },
    mutations: {
        setTranslate(state, translate) {
            state.translate = translate;
        },
        setLocale(state, locale) {
            state.locale = locale;
        }
    },
    actions: {
        async loadTranslates(context) {
            axios.get(window.apiUrl + '/api/external/language/getCustomerTranslate', {
                params: {
                    locale: context.getters.getLocale
                }
            }).then(r => {
                context.commit('setTranslate', r.data.data);
            })
        }
    },
    modules: {}
})
