<template>
    <header class="container">
        <lang-switcher/>
        <a href="#" class="logout" @click.prevent="logout">{{translate['Logout']}}</a>
    </header>
</template>

<script>
import {defineComponent} from 'vue'
import LangSwitcher from "@/components/LangSwitcher.vue";

export default defineComponent({
    name: "Header",
    components: {LangSwitcher},
    computed: {
        translate() {
            return this.$store.getters.getTranslate;
        },
    },
    methods: {
        logout() {
            axios.get(window.apiUrl + '/api/external/auth/logout').then(() => {
                this.$router.push({name: 'auth'});
            })
        }
    }
})
</script>


<style scoped lang="scss">
header {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .logout {
        margin-left: 20px;
    }
}
</style>
