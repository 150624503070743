<template>
    <router-view/>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Loader from '@/components/Loader.vue'
import moment from 'moment'

export default {
    name: 'HomeView',
    components: {Header, Loader},
    data() {
        return {
            isLoading: true,
            moment: moment,
            customer: null
        }
    },
    mounted() {
        const savedLocale = localStorage.getItem('locale');
        if(savedLocale) {
            this.$store.commit('setLocale', savedLocale)
        }
        this.$store.dispatch('loadTranslates');
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/app";

</style>
