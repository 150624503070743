<template>
    <div class="lang-switcher">
        <svg width="30px" height="30px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h48v48H0z" fill="none"/>
            <g id="Shopicon">
                <path d="M14.037,22c0.239-6.944,1.708-13.047,3.885-17.053C10.46,7.326,4.9,13.96,4.101,22H14.037z"/>
                <path d="M14.037,26H4.101C4.9,34.04,10.46,40.674,17.922,43.053C15.745,39.047,14.276,32.944,14.037,26z"/>
                <path d="M33.963,22h9.936C43.1,13.96,37.54,7.326,30.078,4.947C32.255,8.953,33.724,15.056,33.963,22z"/>
                <path d="M33.963,26c-0.239,6.944-1.708,13.047-3.885,17.053C37.54,40.674,43.1,34.04,43.899,26H33.963z"/>
                <path d="M29.958,26H18.042C18.466,37.815,22.539,44,24,44S29.534,37.815,29.958,26z"/>
                <path d="M24,4c-1.461,0-5.534,6.185-5.958,18h11.916C29.534,10.185,25.461,4,24,4z"/>
            </g>
        </svg>
        <div class="lang-switcher-langs">
            <a @click.prevent="setLocale('en')" href="#">English</a>
            <a @click.prevent="setLocale('es')" href="#">Español</a>
        </div>
    </div>
</template>


<script>
export default {
    name: 'LangSwitcher',
    methods: {
        setLocale(locale) {
            localStorage.setItem('locale', locale)
            location.reload();
        }
    }
}
</script>


<style scoped lang="scss">
.lang-switcher {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .lang-switcher-langs {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -57px;
        width: 115px;
        z-index: 10;
        padding: 15px;
        background: #f33a5e;
        color: white;
        display: none;
        box-sizing: border-box;
        text-align: center;

        a {
            margin-bottom: 10px;
            display: inline-block;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:hover {
        .lang-switcher-langs {
            display: block;
        }
    }
}
</style>
