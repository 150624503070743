import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from "axios";

const env = process.env;

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ru'
import 'dayjs/locale/es'

dayjs.extend(utc)

window.dayjs = dayjs;
window.apiUrl = env.VUE_APP_API_URL;
window.axios = axios;

window.prepareErrorResponse = error => {
    if (error.response.status) {
        switch (error.response.status) {
            case 422:
                const errors = [];

                for (let key in error.response.data.errors) {
                    error.response.data.errors[key].forEach(errorText => {
                        errors.push(errorText)
                    })

                }

                return {
                    errors: errors
                };
            default:
                return {
                    errors: []
                }
        }
    }

    return null;
}

window.axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {

    // if(error.response.data.message) {
    //     alert(error.response.data.message);
    // }

    return Promise.reject(error);
});

createApp(App).use(store).use(router).mount('#app')
